import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"

import "./pricing.css"

export default function Pricing({data}) {
    return (
        <Layout>
            <div id='content' className='pricing-container'>
                {
                    data.allFile.edges.map((p, index) => {
                        return <Image key={index} fluid={p.node.childImageSharp.fluid}/>
                    })
                }
            </div>
        </Layout>
    )
}

export const query = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "pricing/images"}}, sort: {order: ASC, fields: name}) {
        edges {
            node {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
    }
  }
`
